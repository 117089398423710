#footer {
    text-align: center;
    padding: 10px;
    background: #153949;
    width: 100%;
    bottom: 0;

    p {
        padding: 1rem;
        font-size: medium;
        letter-spacing: 2px
    }
}