@import "commonStyles";

.menu-items {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 1rem;
    background-image: linear-gradient(to bottom, $primary-color, rgba(0, 0, 0, 0) 100%);
    text-align: center;
    font-weight: 200;
    line-height: 1;
    font-size: $x-small;
    backdrop-filter: blur(2px);

    li {
        display: inline;
        padding-right: 3.8rem;
        padding-left: 3.8rem;

        a {
            display: inline-block;
            padding: 10px;
            transition: color 0.3s ease-out, font-size 0.3s ease-out
        }

        a:hover {
            color: $accent-color;
            transition: color 0.3s ease-in-out, font-size 0.3s ease-in-out;
        }
    }
}

.mobile-burger {
    height: 32px;
    width: 43px;
    margin: 3%;
    position: absolute;
    top: 10px;
    right: 3%;
    display: inline-block;
    vertical-align: middle;
    z-index: 20;
    display: none;

    span {
        display: block;
        background: $font-color;
        width: 100%;
        height: 4px;
        margin-top: -2px;
        position: absolute;
        left: 0;
        top: 50%;
    }
}

.mobile-burger:before,
.mobile-burger:after {
    content: "";
    display: block;
    background: $font-color;
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: all 0.3s ease;
}

.mobile-burger:before {
    top: 2px;
    margin-top: -2px;
}

.mobile-burger:after {
    bottom: 2px;
    margin-bottom: -2px;
}

@media (max-width: 1090px) {
    .mobile-burger {
        display: block;
        position: fixed;
        float: right;
        z-index: 9999;
        backdrop-filter: blur(5px);
    }

    .menu-items {
        padding-top: 60px;
        display: none;
        height: 100%;
        background-color: $primary-color;
        font-size: $small;
        z-index: 999;

        li {
            display: block;
            padding-right: 0;
            padding-left: 0;

            a {
                padding: 30px;
            }
        }

        &.open {
            display: block; // Show menu items when the 'open' class is applied
        }

    }
}