@import "commonStyles";

.login {
    text-align: center;
    justify-content: center;

    input {
        width: 33.33%;
        height: 40px;
        margin: 10px;
        padding: 10px;
        font-size: 1.2rem;
        border-radius: 10px;
        color: black;
    }

    Button {
        width: 10%;
        padding: 10px 20px;
        margin: 10px;
        border-radius: 10px;
        color: black;
        font-size: 1.5rem;
        font-family: $font-family;
        // margin-bottom: 22%;
    }

    &.loggedIn {
        display: none;
    }

    &.loggedOut {
        display: block;
        padding-bottom: 50vh;
    }
}

.admin-modal {
    text-align: center;

    Button {
        width: 10%;
        padding: 10px 20px;
        margin: 10px;
        border-radius: 10px;
        color: black;
        font-size: 1.5rem;
        font-family: $font-family;
        // margin-bottom: 22%;
    }

    &.loggedIn {
        display: block;
    }

    &.loggedOut {
        display: none;
    }
}

.admin-showcase {
    input {
        color: black;
    }
}