@import "commonStyles";

.modal {
    background: $gradient;
    height: 100%;
    width: 100vw;
    z-index: 99;
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    animation: slideDown 0.5s ease forwards;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

@keyframes slideDown {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.modal.slide-up {
    animation: slideUp 0.5s ease forwards;
}

@keyframes slideUp {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.modal-intro {
    text-align: center;

    h1 {
        padding: 0 0 20px 0;
        font-size: $large;
        color: $accent-color;
    }

    h3 {
        padding-bottom: 4rem;
        font-size: $xx-small;
        font-weight: 300;
        text-align: center;
    }
}

.modal-link {
    font-size: $x-small;
    text-align: center;
    padding: 30px 100px;
    text-shadow: 2px 2px 20px rgba($color: black, $alpha: 0.7);
    margin: 0 auto;

    a {
        text-decoration: none;
        transition: color 0.2s ease-in-out;
    }

    a:hover {
        color: $accent-color;
    }
}

.modal-image-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 0 20px;
    margin: 15px 0;
}

.modal-link-row {
    display: inline-flex;
}

.modal-image {
    border-radius: 10px;
    max-width: 100%;
}

.modal-video-container {
    margin: 20px;
}


.btn-wrapper {
    padding: 50px 0 0 100px;
    display: inline-flex;
}

.close {
    width: 3em;
    height: 3em;
    border-radius: 100%;
    background-color: $font-color;
    color: black;
    font-size: $xx-small;
    border: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.close:hover {
    transform: scale(1.2) rotate(360deg);
    background-color: $accent-color;
    color: $font-color;
    border: none;
}

@media (max-width: 1090px) {
    .btn-wrapper {
        padding: 30px 50px;
        display: block;
        text-align: left;
    }

    .close {
        width: 1.5em;
        height: 1.5em;

    }

    .modal-intro {
        h1 {
            font-size: $medium;
        }

        h3 {
            font-size: $xx-small;
        }
    }

    .description,
    .disclaimer {
        margin-left: 20px;
        margin-right: 20px;
    }

    .description {
        font-size: 1px;
    }

    .modal-image-row {
        grid-template-columns: repeat(1, 1fr);
    }

    .modal-link-row {
        display: block;
    }
}