@import "commonStyles";

.header {
    height: 100vh;
}

.centerC {
    margin: 0 auto;
    width: 75%;
}

.containerC {
    margin-top: 10%;
    text-align: center;
    float: right;
    width: 25%;

    h3 {
        font-size: $xx-small;
        text-align: center;
    }

    a {
        font-size: $xx-small;
        text-align: center;
        transition: color 0.5s;
    }
}

.containerC:hover a {
    transition: color 0.5s;
    color: $accent-color;
}

.circle {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: inline-block;
}

.contact-img {
    bottom: 1px;
    max-width: 130px;
}


@media (max-width: 1090px) {

    .intro {
        margin: 100px 10px 0;
    }

    .centerC {
        margin: 0 auto;
        width: 100%;
    }

    .containerC {
        width: 100%;
    }

    .circle {
        display: none;
    }

    .contact-img {
        display: none;
    }
}