@import "commonStyles";

hr {
    text-align: center;
    margin: auto;
    width: 80%;
    background-color: $accent-color;
    border: none;
    height: 2px;
}

.intro {
    margin-top: 140px;

    h1 {
        padding: 1rem 0 50px;
        font-size: $large;
        text-align: center;
        color: $accent-color;
    }
}


.about-container {
    padding: 50px 4rem 2rem;
}

#aboutPic {
    width: 500px;
    border-radius: 60px;
    float: right;
    padding: 20px;
}

.text {

    text-align: left;
    font-size: $small;
    font-weight: 200;
    line-height: 1.5;

    a {
        color: $accent-color;
        border-bottom: 3px solid transparent;
        transition: border 0.3s ease-in-out;
    }

    a:hover {
        border-color: $accent-color;
    }
}

@media (max-width: 1090px) {

    .intro {
        h1 {
            padding: 0 0 20px;
            font-size: $large;
            text-align: center;
            color: $accent-color;
        }
    }

    hr {
        height: 2px;
    }

    .text {
        font-size: $x-small;
        text-align: center;
    }

    .about-container {
        display: flex;
        padding: 20px 20px;
    }

    #aboutPic {
        display: none;
    }
}