@import "commonStyles";

#container-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $gradient;
}

#content-404 {
    text-align: center;
    background-color: #00000050;
    padding: 100px;
    border-radius: 20px;
    backdrop-filter: blur(10px);
}

#title-404 {
    font-size: $large;
    color: $accent-color;
}

#desc-404 {
    font-size: $x-small;
    color: $font-color;
}