@import "commonStyles";

.description,
.disclaimer {
    margin-left: 200px;
    margin-right: 200px;
    font-weight: 200;
    text-align: center;
    line-height: 1.5;
    padding-bottom: 30px;
}

.description {
    font-size: $small;
}

.disclaimer {
    font-size: $x-small;
    font-style: italic;
}

.groupInst {
    font-size: $xx-small;
    font-style: italic;
    font-weight: 200;
    text-align: center;
    padding-bottom: 30px;
}

.row {
    padding: 20px;
}

.column {
    float: left;
    position: relative;
    width: 33.33%;
}

.row::after {
    clear: both;
    content: "";
    display: table;
}

.middle {
    left: 50%;
    top: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    pointer-events: none;
}

.middle-text {
    font-weight: 600;
    color: $font-color;
    font-size: 3vw;
    padding: 1vh 1vw;
    border-radius: 20px;
    background-color: rgba($color: #000000, $alpha: 0.5);
}

.thumb-container {
    overflow: hidden;
    border-radius: 10px;
    padding: 10px;
}

.thumb-image {
    width: 100%;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    filter: drop-shadow(5px 5px 2px rgba($primary-color, 0.8));
}

.thumb-container:hover .thumb-image {
    opacity: 0.3;
    transition: opacity 0.5s;
}

.thumb-container:hover .middle {
    opacity: 1;
}

#github-text {
    font-size: $xx-small;
    font-weight: 300;
    letter-spacing: 1.5px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;

    a {
        color: $accent-color;
    }
}


@media (max-width: 1090px) {

    .description,
    .disclaimer {
        margin-left: 20px;
        margin-right: 20px;
    }

    .row {
        padding: 0px;
    }

    .column {
        width: 100%;
        text-align: center;
    }

    .middle {
        opacity: 100;
        position: relative;
        pointer-events: auto;
        transform: translate(-50%, 0%);
    }

    .middle-text {
        font-weight: 300;
        font-size: $x-small;
        padding: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 0;
        width: 85vw;
        padding: 20px 10px;
        text-decoration: underline;
        margin: 10px 0;
    }

    .thumb-container {
        padding-top: 0;
        padding-bottom: 0;
        display: inline-block;
    }


    .thumb-image {
        display: none;
    }

    .groupInst {
        display: none;
    }

    #github-text {
        padding-top: 10px;
        padding-bottom: 20px;
    }
}